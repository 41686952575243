<template>
	<v-card elevation="0">
		<v-card-title class="px-0 pt-2">
			<v-icon class="mr-2">mdi-cast-education</v-icon>
			<router-link
				:to="{ name: 'mypage-market-course' }"
				class="text-decoration-none"
			>
				{{ course.title }}
			</router-link>
			<v-icon>mdi-chevron-right</v-icon>
			<strong>강의 리스트</strong>
		</v-card-title>
		<my-page-market-course-lecture-player
			:selected-lecture="lecture"
			@fetch-next-lecture="isFetchNextLecture = $event"
			v-if="lecture"
		/>
		<my-page-market-course-lecture-list
			:course-id="marketCourseId"
			:fetch-next-lecture="isFetchNextLecture"
			@selected-lecture="lecture = $event"
		/>
	</v-card>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'

import { useRouter } from '@core/utils'

import MarketCourseService from '@/services/MarketCourseService'

import MyPageMarketCourseLecturePlayer from './MyPageMarketCourseLecturePlayer.vue'
import MyPageMarketCourseLectureList from './MyPageMarketCourseLectureList.vue'

export default {
	components: {
		MyPageMarketCourseLecturePlayer,
		MyPageMarketCourseLectureList,
	},
	setup() {
		const { route } = useRouter()
		const course = ref({})
		const lecture = ref(null)
		const isFetchNextLecture = ref(false)
		const marketCourseId = route.value.params.marketCourseId

		const getMarketCourseDetail = async () => {
			try {
				course.value = await MarketCourseService.getMarketCourseDetail(
					marketCourseId,
				)
			} catch (e) {
				console.error(e)
			}
		}

		watch(
			() => lecture.value,
			() => {
				window.scrollTo(0, 0)
			},
			{
				immediate: true,
			},
		)

		onMounted(() => {
			getMarketCourseDetail()
		})

		return {
			lecture,
			course,
			isFetchNextLecture,
			marketCourseId,
		}
	},
}
</script>
